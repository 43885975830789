<script>
import { defineComponent, ref } from 'vue'

import { animations } from '@/assets/data/animations'

const getBlob = async url => URL.createObjectURL(
  await (await fetch(url)).blob()
)

export default defineComponent({
  name: 'ThePreloader',
  setup () {
    const loaded = ref(false)
    const assetsLoading = []

    animations.forEach((animation, i) => {
      animation.audio && assetsLoading.push(getBlob(animation.audio))
      animation.bodymovin?.src && assetsLoading.push(getBlob(animation.bodymovin.src))
    })

    Promise.all(assetsLoading)
      .then((data) => {
        console.log(data)
        loaded.value = true
        return data
      })
      .catch(err => {
        console.error(err)
      })

    return {
      loaded,
      visible: ref(true)
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition>
      <div v-if="visible" id="app-loading">
        <svg class="spinner" viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $c-blue;
  color: $c-white;
}

.spinner {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  animation: rotate 2s linear infinite;

  .path {
    animation: dash 1.5s ease-in-out infinite;
    stroke: currentColor;
    stroke-linecap: round;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
