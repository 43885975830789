import audio0 from '@/assets/audio/03-01/0.mp3'
import audio1 from '@/assets/audio/03-01/1.mp3'
import audio2 from '@/assets/audio/03-01/2.mp3'
import audio3 from '@/assets/audio/03-01/3.mp3'
import audio4 from '@/assets/audio/03-01/4.mp3'
import audio5 from '@/assets/audio/03-01/5.mp3'
import audio6 from '@/assets/audio/03-01/6.mp3'
import audio7 from '@/assets/audio/03-01/7.mp3'
import audio8 from '@/assets/audio/03-01/8.mp3'
import audio9 from '@/assets/audio/03-01/9.mp3'
import audio10 from '@/assets/audio/03-01/10.mp3'
import audio11 from '@/assets/audio/03-01/11.mp3'
// bodymovin
import _1Bodymovin from '@/assets/bodymovin/03-01/1.json?url'
import _2Bodymovin from '@/assets/bodymovin/03-01/2.json?url'
import _3Bodymovin from '@/assets/bodymovin/03-01/3.json?url'
import _4Bodymovin from '@/assets/bodymovin/03-01/4.json?url'
import _5Bodymovin from '@/assets/bodymovin/03-01/5.json?url'
import _6Bodymovin from '@/assets/bodymovin/03-01/6.json?url'

/*
function sliceIntoChunks (arr, chunkSize) {
  const res = Array(chunkSize).fill(0).map(() => ([]))
  console.log(res)
  let index = 0
  for (let i = 0; i < arr.length; i++) {
    console.log(i)
    res[index % chunkSize].push(arr[i])
    index++
  }
  return res
}

const list = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

console.log(sliceIntoChunks(list, 12))
*/

const keyboardCombinations = [
  ['A', 'M', 'Y'],
  ['B', 'N', 'Z'],
  ['C', 'O'],
  ['D', 'P'],
  ['E', 'Q'],
  ['F', 'R'],
  ['G', 'S'],
  ['H', 'T'],
  ['I', 'U'],
  ['J', 'V'],
  ['K', 'W'],
  ['L', 'X']
]

export const animations = [
  {
    keys: keyboardCombinations[0],
    audio: audio0,
    textIndex: 0,
    bodymovin: {
      src: _1Bodymovin,
      translate: false,
      flipX: true,
      flipY: true,
      renderSettings: { preserveAspectRatio: 'none' }
    }
  },
  {
    keys: keyboardCombinations[1],
    audio: audio1,
    textIndex: 1,
    bodymovin: {
      src: _2Bodymovin,
      translate: true,
      scale: 2,
      flipX: true,
      flipY: true
    }
  },
  {
    keys: keyboardCombinations[2],
    audio: audio2,
    textIndex: 2,
    bodymovin: {
      src: _3Bodymovin,
      translate: false,
      flipX: false,
      flipY: false,

      renderSettings: { preserveAspectRatio: 'xMidYMid slice' }
    }
  },
  {
    keys: keyboardCombinations[3],
    audio: audio3,
    textIndex: 3,
    bodymovin: {
      src: _4Bodymovin,
      translate: true,
      flipX: false,
      flipY: false
    }
  },
  {
    keys: keyboardCombinations[4],
    audio: audio4,
    textIndex: 4,
    bodymovin: {
      src: _5Bodymovin,
      translate: true,
      flipX: true,
      flipY: true
    }
  },
  {
    keys: keyboardCombinations[5],
    audio: audio5,
    textIndex: 0,
    bodymovin: {
      src: _6Bodymovin,
      translate: false,
      flipX: false,
      flipY: false,
      class: 'fill-theme',
      renderSettings: { preserveAspectRatio: 'xMidYMid slice' }
    }
  },
  {
    keys: keyboardCombinations[6],
    audio: audio6,
    textIndex: 2,
    changeTheme: true
  },
  {
    keys: keyboardCombinations[7],
    audio: audio7,
    textIndex: 0,
    bodymovin: {
      src: _1Bodymovin,
      translate: false,
      flipX: true,
      flipY: true,
      renderSettings: { preserveAspectRatio: 'none' }
    }
  },
  {
    keys: keyboardCombinations[8],
    audio: audio8,
    textIndex: 1,
    bodymovin: {
      src: _5Bodymovin,
      translate: true,
      flipX: true,
      flipY: true
    }
  },
  {
    keys: keyboardCombinations[9],
    audio: audio9,
    textIndex: 2,
    bodymovin: {
      src: _4Bodymovin,
      translate: true,
      flipX: false,
      flipY: false
    }
  },
  {
    keys: keyboardCombinations[10],
    audio: audio10,
    textIndex: 3,
    bodymovin: {
      src: _2Bodymovin,
      translate: true,
      scale: 2,
      flipX: true,
      flipY: true
    }
  },
  {
    keys: keyboardCombinations[11],
    audio: audio11,
    textIndex: 4,
    bodymovin: {
      src: _6Bodymovin,
      translate: false,
      flipX: false,
      flipY: false,
      class: 'fill-theme',
      renderSettings: { preserveAspectRatio: 'xMidYMid slice' }
    }
  }
]
